export const environment:any={
	production:false,
	firebase:{
		projectId:'beatfulness-dev',
		appId:'1:653992374586:web:ae56904524d3c65923e003',
		storageBucket:'beatfulness-dev.appspot.com',
		apiKey:'AIzaSyDSgphG-VbOQnjriuMZgsMatzKpIl8Uapc',
		authDomain:'beatfulness-dev.firebaseapp.com',
		messagingSenderId:'653992374586',
		measurementId:'G-3BFTVN744P'
	}
};




