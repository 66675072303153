<div class="card-header">
	<h1 class="md-36 md-bold">Terms of Use</h1>
</div>
<div class="card-content">
	<p class="md-16">Last updated September 21, 2017</p><br>
	<h2 class="md-24 md-bold">1. ABOUT US</h2>
	<p class="md-16">We are Beatfulness , a small startup located in Bogotá, Colombia. We are a team of two and a dog called Lena. We curated all the content showed in the Beatfulness iOS App, we do the development and design of the iOS App currently on the App Store as the name of Beatfulness - Binaural Beats with Sacred Geometry.</p>
	<br>
	<h2 class="md-24 md-bold">2. ABOUT OUR SERVICES</h2>
	<p class="md-16">Beatfulness provides easy tools and inspiring content to help improve your practice of self-awarenes, every day. We deliver to users (“Users,” “you”, “your”) meditation and mental wellness tools via the Beatfulness mobile iOS application.</p>
	<br>
	<h2 class="md-24 md-bold">3. ABOUT THESE TERMS OF SERVICE</h2>
	<p class="md-16">These Beatfulness Terms of Service (“Terms of Service”) are terms for your use of the Service. You should also review Beatfulness’s Privacy Policy, currently located at www.beatfulness.com/privacy-policy, and accessible through the Beatfulness mobile application, which contains important information about how we use your data. By accessing and using the Service, whether on a free or subscription basis, you agree to comply with and be bound by these Terms of Service, by using the Beatfulness iOS app. Beatfulness reserves the right, at its sole discretion, to modify, discontinue, or terminate the Services at any time and without prior notice. If you do not agree to these Terms of Service, you should not access or use the Service.</p>
	<br>
	<h2 class="md-24 md-bold">4. CHANGES TO THESE TERMS OF SERVICE</h2>
	<p class="md-16">Beatfulness may change these Terms of Service from time to time. We will usually inform you of any changes by posting the updated Terms of Service through the iOS mobile application. If we make any material changes to these Terms of Service, we will post a notification through the iOS mobile application by asking you to review the changes to these Terms of Service the next time you access the Service. Your continued use of the Service after the time the changes are communicated will indicate your acceptance of the Terms of Service, including those changes.</p>
	<br>
	<h2 class="md-24 md-bold">5. YOUR ACCOUNT</h2>
	<p class="md-16">a. Since we don’t have a registration process on the Service and we don’t use any personal information or related to the identity of the user of the Service we talk about the account for the user using the iOS mobile application daily where we can track information for analytics purposes indicated in the privacy policy document refer at the beginning of this Terms of Service.</p>
	<br>
	<h2 class="md-24 md-bold">6. PERMITTED USE OF SERVICES</h2>
	<p class="md-16">The Service is made available to you for your non-commercial use only. Organizations or schools are encouraged to make the Service available to individual users under individual iOS mobile application installations. You agree not to use Beatfulness or any of its content to promote a product or service. You may not modify, reproduce, store, distribute, publish, license, or create derivative works from the Service or from the Content. You may not access Beatfulness in any way that could damage, disable or overburden the Service. You may not use any third-party APIs, software, tools or scripts to access or modify the Service or underlying technology, unless you are authorized in writing by Beatfulness.</p>
	<br>
	<h2 class="md-24 md-bold">7. LICENSE & INTELLECTUAL PROPERTY OWNERSHIP</h2>
	<p class="md-16">Beatfulness grants you the personal, nonexclusive, revocable, non-transferable right to use the software and technology that is provided as part of the Service for your use in accordance with these Terms of Service.
		You acknowledge that Beatfulness owns all right, title and interest in and to the Service, including without limitation, all underlying software and technology, and all other Intellectual Property Rights in relation to the Service, other than that owned by third parties. “Intellectual Property Rights” means any and all intellectual property rights existing in any part of the world under applicable law, including without limitation patent law, copyright law, trade secret law, trademark law, unfair competition law, and any and all applications, renewals, extensions and restorations of such rights, now or hereafter in effect worldwide.</p>
	<br>
	<h2 class="md-24 md-bold">8. CONTENT</h2>
	<p class="md-16">The Services contain different types of Content: Beatfulness Content and Third Party Content. In all cases, “Content” means any and all text, images, photos, sounds, messages, files, feedback, bug reports, or other materials and works of authorship. Each type of Content that you encounter has different rules and restrictions, as set forth below:<br>
		a. Beatfulness content and ownership. As between Beatfulness and you, Beatfulness owns or controls all right, title and interest in the Service and in the Content included within the Service, including its logos, trademarks and all other Intellectual Property Rights it controls (“Beatfulness Content”).<br>
		b. Third party content. Content from third parties may be displayed to you through and as part of the Service (“Third Party Content”). We do not exercise control over Third Party Content and you agree that we are not responsible for any Third Party Content in this case the sounds for your daily meditation or binaural beats or singles as we called them in the iOS mobile application.<br>
		c. Content restrictions. You agree not to (i) modify, copy, publish, display, transmit, distribute, perform, sell or create derivative works based on the Content and Service; (ii) rent, lease, loan or sell access to the Service; or (iii) decompile, reverse engineer or copy any Content (other than the Content you post) or the Service. You also agree to not remove, obscure or alter Beatfulness’s or a third party’s copyright notice, logos, trademarks or other proprietary rights notices affixed to or contained within the Service. You will not interfere with, disrupt or create an undue burden on the Service or the networks or services connected to the Service.<br>
		d. Prohibited content. You agree that you will not post or share through the Service any Prohibited Content. “Prohibited Content” includes, without limitation, Content that: (i) is offensive or promotes racism, bigotry, hatred or physical harm of any kind; (ii) harasses or advocates harassment of another person; (iii) involves the transmission of “junk mail,” “chain letters,” unsolicited mass mailing or “spamming”; (iv) is false, misleading, libelous, defamatory or promotes, endorses or furthers illegal activities; (v) infringes the Intellectual Property Rights of another person; (vi) is involved in the exploitation of persons under the age of eighteen (18) in a sexual or violent manner, or solicits personal information from anyone under thirteen (13); (vii) contains viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, spyware, malware or other harmful content or code; or (viii) otherwise violates applicable law, the terms of these Terms of Service or
		potentially creates liability for Beatfulness. Any units of the iOS mobile application posting, hosting or recommending Prohibited Content are subject to termination. We reserve the right to remove Content or links to Content that we determine in our sole discretion is unlawful, fraudulent, threatening, libelous, defamatory, obscene or otherwise objectionable. We also reserve the right to remove Content that we determine in our sole discretion is primarily intended to promote a product or service. We have the right (but not the obligation) to investigate or review any Content and delete (or modify) any Content that in our sole judgment violates these Terms of Service or which is Prohibited Content, or may otherwise violate the rights, harm or threaten the safety of any User or any other person, or create potential liability for us or any User.
	</p><br>
	<h2 class="md-24 md-bold">9. COPYRIGHT POLICY</h2>
	<p class="md-16">Beatfulness respects the Intellectual Property Rights of creators. If you believe that your work has been displayed, posted, copied or included within the Service in a way that constitutes copyright infringement, please first check to see if the work originated from a third party service. You may notify such third party service directly about alleged copyright violations. For all other copyright notifications, please provide the following information to beatfulness@gmail.com : (i) a digital or physical signature of the person authorized to act on behalf of the owner of the copyright interest; (ii) a description of the copyrighted work that you claim has been infringed; (iii) a description of where the material that you claim is infringing is located on the Services; (iv) your address, telephone number and e-mail address; (v) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the
		law; (vi) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
		We reserve the right to remove Content alleged to be infringing in our sole discretion and without notice. It is our policy to terminate the account of any User who repeatedly is accused of infringing copyrights.</p>
	<br>
	<h2 class="md-24 md-bold">10. SUBSCRIPTION SERVICES & OTHER CHARGES</h2>
	<p class="md-16">Features of the Beatfulness Service may require a subscription which may be charged in advance or periodically, according to the plan you selected when you began your subscription (the “Subscription Services”).
		Your payment may be charged directly by Beatfulness through your mobile application provider in this case Apple, in which case you should look to the provider’s terms regarding in –app payments.<br>
		a. Your subscriber account. If you purchase Subscription Services, you agree that your subscription is personal to you and the members of your household, and you may not share your account and password with others. Any such sharing by you of your account and password is a violation of these Terms of Service and may result in the cancellation of your subscription without refund. Most subscriptions will automatically renew at the end of the subscription period with a charge through the payment provider you have chosen in this case Apple. For all subscription plans, if you do not wish your subscription to renew, you may cancel at any time through your Apple ID account indicated it in the Privacy Policy document. Beatfulness will not refund any unused or inadvertently renewed subscriptions, and it is always within Beatfulness’s sole discretion whether to grant any refunds. Child Users may not sign up for Subscription Services. If the parent or guardian of a Child User wishes to
		purchase a Subscription Service for the Child User, the parent or guardian must create an account and gift the subscription to the Child User. In that event, all data log will relate to the parent or guardian and no personal information will be retained for the Child User.<br>
		b. Payment. When you pay for a subscription, Beatfulness will not take your credit card or other payment information nor billing address and won’t be collecting and use such information in accordance with the Beatfulness Privacy Policy, all the process is made by the third party in this case Apple. If your designated payment method is refused or returned, you acknowledge that Beatfulness may immediately cancel your subscription. Prices and payments are in United States currency only, and you are responsible for paying any applicable taxes on the subscription, unless stated otherwise.<br>
		We reserve the right to change fees by publication in the Beatfulness Services and any new subscription fees will apply after the end of your current subscription period.
	</p><br>
	<h2 class="md-24 md-bold">11. YOUR BREACH OF THESE TERMS OF SERVICE</h2>
	<p class="md-16">Any breach by you of these Terms of Service may result in, among other things, termination or suspension of your rights to use the Service along with the cancellation of your subscription, if applicable.</p>
	<br>
	<h2 class="md-24 md-bold">12. DISCLAIMER OF WARRANTIES</h2>
	<p class="md-16">THE SERVICES ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITH NO WARRANTIES WHATSOEVER. BEATFULNESS MAKES NO CLAIMS WHATSOEVER ABOUT ANY MEDICAL OR HEALTH BENEFITS FROM THE USE OF THE BEATFULNESS SERVICE AND DOES NOT PURPORT TO GIVE MEDICAL ADVICE. IT IS SOLELY YOUR RESPONSIBILITY TO CONSULT WITH YOUR OWN MEDICAL AND HEALTH PRACTITIONERS ABOUT YOUR OWN PHYSICAL AND MENTAL HEALTH AND THE MENTAL AND PHYSICAL HEALTH OF ANY CHILD USER FOR WHOM YOU ARE RESPONSIBLE. NO DOCTOR-PATIENT RELATIONSHIP IS CREATED BY YOUR USE OF THE BEATFULNESS SERVICE. BEATFULNESS EXPRESSLY DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT OF PROPRIETARY RIGHTS. BEATFULNESS DISCLAIMS ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AVAILABILITY, QUALITY AND PERFORMANCE OF THE CONTENT AND THE SERVICE.
		BEATFULNESS DISCLAIMS ANY RESPONSIBILITY FOR ANY HARM RESULTING FROM YOUR VIEWING OF THE CONTENT AND USE OF THE SERVICE, AND DOES NOT GUARANTEE THAT YOU WILL RECEIVE ANY BENEFIT FROM YOUR USE OF THE SERVICES. YOU UNDERSTAND AND AGREE THAT YOU VIEW THE CONTENT AND USE THE SERVICES AT YOUR OWN DISCRETION AND RISK.
		Some countries or other jurisdictions do not allow the exclusion of implied warranties, so the above exclusions may not apply to you.
		You may also have other rights that vary from country and jurisdiction to jurisdiction.</p><br>
	<h2 class="md-24 md-bold">13. LIMITATION OF LIABILITY</h2>
	<p class="md-16">Under no circumstances shall Beatfulness be liable to any User in connection with that User’s use or misuse of the Content or the Service. Such limitation of liability shall apply to prevent recovery of indirect, incidental, consequential, special, exemplary, and punitive damages whether such claim is based on warranty, contract, tort (including negligence), or otherwise (even if Beatfulness has been advised of the possibility of such damages). This limitation of liability shall apply whether the damages arise from use or misuse of and reliance on the Content or Service, from inability to use the Content or Service, from any claim relating to the user of the Content or the Service, or from the interruption, suspension, or termination of the Content or Service. Some countries or other jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential damages, so the above limitations and exclusions may not apply to you.</p>
	<br>
	<h2 class="md-24 md-bold">14. RELEASE</h2>
	<p class="md-16">You hereby release us from claims, demands, any and all losses, damages, rights, claims, and actions of any kind including, without limitation, personal injuries, emotional distress, death and property damage, that is either directly or indirectly related to or arises from (i) any interaction you have with other Users, (ii) your use of the Service or (iii) your participation in any offline events.</p>
	<br>
	<h2 class="md-24 md-bold">15. INDEMNITY</h2>
	<p class="md-16">You agree to defend, indemnify and hold Beatfulness harmless from and against any claims, liabilities, damages, losses and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Content or Service or your violation of these Terms of Service.</p>
	<br>
	<h2 class="md-24 md-bold">16. RESPONSIBILITY</h2>
	<p class="md-16">Your interaction with other Users on the Service or with third parties, including any payment or delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and the other User, advertiser or third party. You agree that Beatfulness will not be responsible for any loss or damage incurred as the result of any such dealings or with respect to any third party’s use or disclosure of your personal information that you make available to them. If there is a dispute between you and any third party (including any User), we are under no obligation to become involved and you agree not to involve us unless we request otherwise in writing; however, we reserve the right, but have no obligation, to monitor disputes between you and other Users or third parties.</p>
	<br>
	<h2 class="md-24 md-bold">17. COMMENTS</h2>
	<p class="md-16">If you have comments on the Beatfulness Service or ideas on how we might improve, please contact us at beatfulness@gmail.com. If you submit any comments or ideas to us, you hereby grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sub-licensable and transferable license under any and all Intellectual Property Rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit the comments and ideas for any purpose. The licenses granted under this Section shall survive the termination or expiration of these Terms of Service.</p>
	<br>
	<h2 class="md-24 md-bold">18. INITIAL RESOLUTION OF QUESTIONS OR DISPUTES</h2>
	<p class="md-16">If you have any questions or concerns about the Service or these Terms of Service, we strongly encourage you to first contact us beatfulness@gmail.com directly.</p>
	<br>
	<h2 class="md-24 md-bold">19. LAW AND JURISDICTION</h2>
	<p class="md-16">These Terms of Service shall be governed by the laws of Colombia without giving effect to any conflicts of laws principles that would require the application of the law of a different jurisdiction. You agree that any claim or dispute you may have against Beatfulness must be resolved by the laws impose in Colombia. You agree to submit to the personal jurisdiction of the courts located in Colombia for the purpose of litigating all such claims or disputes.</p>
	<br>
	<h2 class="md-24 md-bold">20. MISCELLANEOUS PROVISIONS</h2>
	<p class="md-16">If for any reason a court finds any provision or portion of this Terms of Service to be unenforceable, the remainder of the Terms of Service will continue in full force and effect.
		The provisions of these Terms of Service that by their nature are continuing will survive any termination. This Terms of Service is the entire agreement between the parties with respect to the use of the Services and supersedes and replaces all prior or contemporaneous communications or agreements, written or oral. Any waiver of any provision of these Terms of Service will be effective only if in writing and signed by Beatfulness.</p>
	<br>
</div>
