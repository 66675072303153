import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map,shareReplay} from 'rxjs/operators';
import {Title,Meta} from '@angular/platform-browser';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {MediaObserver} from '@angular/flex-layout';
import {CookieService} from 'ngx-cookie-service';
import * as moment from 'moment';
import {Apollo} from 'apollo-angular';
import {OverlayContainer} from '@angular/cdk/overlay';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
	providedIn:'root'
})
export class AppService{
	private title:Title;
	private meta:Meta;
	private router:Router;
	private httpClient:HttpClient;
	private mediaMatcherXSmall:MediaQueryList;
	private mediaMatcherSmall:MediaQueryList;
	private mediaMatcherMedium:MediaQueryList;
	private mediaMatcherLarge:MediaQueryList;
	private mediaMatcherXLarge:MediaQueryList;
	private mediaMatcherDarkTheme:MediaQueryList;
	private readonly apollo:Apollo;
	private overlayContainer:OverlayContainer;
	private matSnackBar:MatSnackBar;
	window:Window;
	localStorage:Storage;
	sessionStorage:Storage;
	cookieService:CookieService;
	breakpointObserver:BreakpointObserver;
	moment:object;
	darkTheme:boolean;
	isHandsetObservable:Observable<boolean>;
	isTabletObservable:Observable<boolean>;
	isMediumObservable:Observable<boolean>;
	isSmallObservable:Observable<boolean>;
	mediaObserver:MediaObserver;
	
	constructor(
		title:Title,
		meta:Meta,
		router:Router,
		httpClient:HttpClient,
		cookieService:CookieService,
		breakpointObserver:BreakpointObserver,
		mediaObserver:MediaObserver,
		apollo:Apollo,
		overlayContainer:OverlayContainer,
		matSnackBar:MatSnackBar
	){
		this.title=title;
		this.meta=meta;
		this.router=router;
		this.httpClient=httpClient;
		this.cookieService=cookieService;
		this.breakpointObserver=breakpointObserver;
		this.mediaObserver=mediaObserver;
		this.apollo=apollo;
		this.overlayContainer=overlayContainer;
		this.matSnackBar=matSnackBar;
		this.window=window;
		this.localStorage=localStorage;
		this.sessionStorage=sessionStorage;
		this.moment=moment;
		this.mediaMatcherXSmall=this.window.matchMedia(Breakpoints.XSmall);
		this.mediaMatcherSmall=this.window.matchMedia(Breakpoints.Small);
		this.mediaMatcherMedium=this.window.matchMedia(Breakpoints.Medium);
		this.mediaMatcherLarge=this.window.matchMedia(Breakpoints.Large);
		this.mediaMatcherXLarge=this.window.matchMedia(Breakpoints.XLarge);
		this.mediaMatcherDarkTheme=this.window.matchMedia('(prefers-color-scheme: dark)');
		this.mediaMatcherDarkTheme.addEventListener('change',this.checkDarkTheme.bind(this));
		this.checkDarkTheme();
		this.isHandsetObservable=this.breakpointObserver.observe(Breakpoints.Handset)
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		this.isTabletObservable=this.breakpointObserver.observe(Breakpoints.Tablet)
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		this.isMediumObservable=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small,Breakpoints.Medium])
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		this.isSmallObservable=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);

	}
	
	checkDarkTheme():void{
		if(this.window.localStorage.getItem('darkTheme')==null){
			if(this.mediaMatcherDarkTheme.matches){
				this.darkTheme=true;
				this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
			}else{
				this.darkTheme=false;
			}
		}else{
			if(parseInt(this.window.localStorage.getItem('darkTheme')!)){
				this.darkTheme=true;
				this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
			}else{
				this.darkTheme=false;
			}
		}
	}
	
	isScreenXSmall():boolean{
		return this.mediaMatcherXSmall.matches;
	}
	
	isScreenSmall():boolean{
		return this.mediaMatcherSmall.matches;
	}
	
	isScreenMedium():boolean{
		return this.mediaMatcherMedium.matches;
	}
	
	isScreenLarge():boolean{
		return this.mediaMatcherLarge.matches;
	}
	
	isScreenXLarge():boolean{
		return this.mediaMatcherXLarge.matches;
	}
	
	isDarkTheme():boolean{
		return this.mediaMatcherDarkTheme.matches;
	}
	
	toggleDarkTheme():void{
		this.darkTheme= !this.darkTheme;
		if(!this.darkTheme){
			this.overlayContainer.getContainerElement().classList.remove('app-dark-theme');
			this.window.localStorage.setItem('darkTheme','0');
		}else{
			this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
			this.window.localStorage.setItem('darkTheme','1');
		}
	}
	
	updateSeo(title:string,description?:string,keywords?:string,photo?:string):void{
		this.title.setTitle(title);
		this.meta.updateTag({content:title},'property="og:title"');
		this.meta.updateTag({content:this.window.location.href},'property="og:url"');
		if(description){
			this.meta.updateTag({content:description},'name="description"');
			this.meta.updateTag({content:description},'property="og:description"');
		}
		if(keywords){
			this.meta.updateTag({content:keywords},'name="keywords"');
		}
		if(photo){
			this.meta.updateTag({content:photo},'name="twitter:image"');
			this.meta.updateTag({content:photo},'property="og:image"');
		}
	}
	
}
