<div class="card-header">
	<h1 class="md-36 md-bold">Privacy Policy</h1>
</div>
<div class="card-content">
	<p class="md-16">Last updated September 18, 2017
		Beatfulness respects the privacy of its users. We believe on the power to take a few minutes daily for you, to get to know you better, to practice self-awareness through tools like audio and visuals. This Privacy Policy (“Privacy Policy”) informs you about our policies and practices regarding the collection and use of your information and lets you know about options that you have to control or restrict the availability and use of your information.</p>
	<br>
	<h2 class="md-24 md-bold">1. ABOUT US</h2>
	<p class="md-16">We are Beatfulness, a small startup located in Bogotá, Colombia. We are a team of two and a dog called Lena. We curated all the content showed in the Beatfulness iOS App, we do the development and design of the iOS App currently on the App Store as the name of Beatfulness - Binaural Beats with Sacred Geometry.</p>
	<br>
	<h2 class="md-24 md-bold">2. ABOUT OUR SERVICES</h2>
	<p class="md-16">Beatfulness provides easy tools and inspiring content to help improve your practice of Self- Awarenes, every day. We deliver to users (“Users,” “you”, “your”) meditation and mental wellness tools via the Beatfulness mobile iOS application.</p>
	<br>
	<h2 class="md-24 md-bold">3. ABOUT THIS PRIVACY POLICY</h2>
	<p class="md-16">This Privacy Policy informs you of Beatfulness’s policies and practices regarding the collection and use of information you submit to us or which we collect about you through our Service. We will only use your information or share your information in the ways we describe in this Privacy Policy. The Privacy Policy also outlines options that you have to control or restrict the availability and use of your information. Wherever you reside or from whichever country you submit your information, you consent to our use of your information, consistent with this Privacy Policy, in Colombia and in other territories where Beatfulness provides its Service.</p>
	<br>
	<h2 class="md-24 md-bold">4. CHANGES TO THIS PRIVACY POLICY</h2>
	<p class="md-16">Beatfulness reserves the right to change this Privacy Policy from time to time. We will usually inform you of any changes by posting the updated Privacy Policy through the mobile application. If we make any material changes to our Privacy Policy, we will post a notification through the mobile application.
		Your continued use of the Services after the changes are communicated will indicate your consent to our use of your information consistent with this Privacy Policy, including those changes.</p>
	<br>
	<h2 class="md-24 md-bold">5. WHY DO WE COLLECT INFORMATION?</h2>
	<p class="md-16">We want to give Users the best experience possible. We collect information to manage the Services, to deliver to our Users a personalized experience, and to improve our Services, features and content.</p>
	<br>
	<h2 class="md-24 md-bold">6. SOME TERMS WE USE IN THIS PRIVACY POLICY</h2>
	<p class="md-16">Important terms that we use in this Privacy Policy is “Log Data”. To explain what we mean by these terms:<br>
		a. “Log Data” is derived by our servers automatically as you interact with the Service, which record information that your mobile device sends whenever you interact with the Service. Log Data is a form of Non-Identifying Information.
	</p><br>
	<h2 class="md-24 md-bold">7. WHAT INFORMATION DO WE COLLECT?</h2>
	<p class="md-16">a. Log Data. When you interact with the Service, our server automatically record Log Data that your mobile device sends. This Log Data may include information such as your mobile device identifier, your computer’s IP (Internet Protocol) address and your operating system, and other analytics that help us deliver and monitor the effectiveness of our Services.
		<br>
		b. Contacting Us. When you leave us feedback or write to us directly, we will collect any information that is contained in your feedback or comment form, which will include your e-mail address and other information that you may submit to us for reference or reply.
	</p><br>
	<h2 class="md-24 md-bold">8. DOES BEATFULNESS USE COOKIES AND OTHER TRACKING METHODS?</h2>
	<p class="md-16">a. We may collect information through the mobile application about your individual device. The purpose of this collection is: (i) to deliver content requested by the Users or to recommend content we think may be of interest; (ii) to optimize the Service for the particular mobile device; and (iii) to gather analytics and data about the way the Service is used through the mobile application.<br>
		b. Your options. Mobile devices may contain settings that allow you to disable tracking and/or analytics. Beatfulness will respect all such instructions it receives. Be aware, however, that if you choose to disable tracking or disengage similar features on your mobile device, some or all areas of the Service may not be optimized for your use and you may not be able to access the functionality of the Service.
	</p><br>
	<h2 class="md-24 md-bold">9. HOW DO WE USE INFORMATION INTERNALLY?</h2>
	<p class="md-16">Log Data is used to monitor and analyze use of the Service, to analyze usage patterns, to optimize your access to our Service, and to control the load on our servers so that we can serve you better.</p>
	<br>
	<h2 class="md-24 md-bold">10. WHEN DO WE SHARE INFORMATION WITH OTHERS, AND WITH WHOM?</h2>
	<p class="md-16">a. Payment Processors. If you purchase the full beatfulness experience, a third party payment processor may have access to your Personal Information for the sole purpose of processing transactions and renewals. Any such payment processors will be bound to maintain all such Personal Information in a secure environment, keep its confidentiality and not use or disclose such information for any purpose other than to validate purchases and operate Subscription Services. If you purchased content or signed up for Subscription Services through an app store, you should refer to that store’s terms for information on how it maintains personal financial information.<br>
		b. Other Service Providers. We may employ third party companies and individuals to help us deliver our Services, to perform maintenance and research services, or to assist us in analyzing how our Services are used (such as infrastructure providers, database management, mobile analytics and improvement of the Service’s features). These third parties may have access to the log data we collect only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.<br>
		c. Business Transfers. If Beatfulness or the assets of Beatfulness are acquired by another company, we may transfer your information in connection with that acquisition or sale and the acquiring company will possess the information collected by us and it will assume the rights and obligations described in this Privacy Policy.
	</p><br>
	<h2 class="md-24 md-bold">11. HOW CAN I EDIT MY INFORMATION OR UNSUBSCRIBE SUBSCRIPTIONS?</h2>
	<p class="md-16">Since we don’t collect any personal information beyond user behavior in the app without relating full name, email address, nicknames, national id number or any other personal sensitive information the only way we can help you is unsubscribing from the service you acquired on the Beatfulness app.
		You may go to Settings -> iTunes & App Store -> Apple Id: your@email.com and click on that option -> View Apple Id - > Subscriptions where you can edit or remove the current subscription you are using to use the Service.
		You can contact us as well to beatfulness@gmail.com to help you with any concern.</p><br>
	<h2 class="md-24 md-bold">12. HOW LONG WILL BEATFULNESS RETAIN INFORMATION?</h2>
	<p class="md-16">Unless there is a specific legal or regulatory requirement for us to keep information, we will retain it for no longer than is reasonably necessary for the purposes for which the data was collected or for which it is to be further processed.</p>
	<br>
	<h2 class="md-24 md-bold">13. WHERE WILL INFORMATION BE COLLECTED AND STORED?</h2>
	<p class="md-16">Beatfulness is located in Colombia.<br>
		Accordingly, log data Information we collect may be transferred to and stored in our server in Colombia or those of third parties that act on our behalf (such as hosting providers). By using the Services you agree to such collection, transfer and storage of the log data information.
	</p><br>
	<h2 class="md-24 md-bold">14. HOW SECURE IS THE INFORMATION?</h2>
	<p class="md-16">You should be aware that while we take industry standard administrative, physical and technological measures to maintain the security of the log data we collect, we are not able to fully eliminate all security risks or potential breaches.</p>
	<br>
	<h2 class="md-24 md-bold">15. WHAT POLICIES APPLY TO THIRD PARTY SERVICES AND APPLICATIONS?</h2>
	<p class="md-16">The Service may contain links or integrations to third party services like apple's subscriptions programs. We have no control over, do not review, and cannot be responsible for these outside services or their content. This Privacy Policy does not govern your visits to these other services, and your access is entirely at your own risk.
		These other services and applications may collect data, or solicit personal information.
		You should refer to any privacy policies on such services and applications to understand how your information may be collected and used, and should choose appropriate settings for sharing your personal information before proceeding to any integration with a third party service in this case Apple.</p>
	<br>
</div>
