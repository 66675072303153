import {Component,Input} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../app.service';

@Component({
	selector:'beatfulness-header',
	templateUrl:'./header.component.html',
	styleUrls:['./header.component.scss']
})
export class HeaderComponent{
	@Input() drawerInput?:MatDrawer;
	appService:AppService;
	
	constructor(appService:AppService){
		this.appService=appService;
		this.drawerInput=undefined;
	}
}
