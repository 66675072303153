<footer>
	<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.gt-md="space-evenly center">
		<div fxFlex="nogrow" fxLayout="column" class="footer-item">
			<div fxFlex fxLayout="column" fxLayoutAlign="center center">
				<a fxFlex fxLayout="row" fxLayoutGap="10px" [routerLink]="['/']" class="no-decoration">
					<img fxFlex src="/assets/images/logo.svg" width="50px" height="50px">
					<h2 *ngIf="!(appService.isHandsetObservable | async)" fxFlex fxFlexAlign="center">Beatfulness</h2>
				</a>
			</div>
		</div>
		<div fxFlex="nogrow" fxLayout="column" fxFlexOffset.lt-md="25px" fxLayoutAlign="start center">
			<a fxFlex fxLayout="row" fxLayoutGap="10px" [routerLink]="['/privacy-policy']" class="font-weight-normal md-16">
				<span fxFlex>Privacy policy</span>
				<mat-icon fxFlex>east</mat-icon>
			</a>
		</div>
	</div>
</footer>
