<div fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
	<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutAlign="space-evenly center">
		<dix *ngIf="!(appService.isSmallObservable | async)" fxFlex="nogrow" fxLayout="row">
			<a fxFlex="nogrow" fxLayout="row" href="https://twitter.com/beatfulness">
				<img fxFlex="nogrow" src="/assets/images/icon-twitter.svg" class="app-logo">
			</a>
		</dix>
		<dix fxFlex="nogrow" fxLayout="column" fxLayoutAlign="space-around start" fxLayoutAlign.lt-md="space-around center" fxLayoutGap="30px" class="download-section">
			<h2 fxFlex class="md-24 md-light-text-color">Binaural Beats with Sacred Geometry for <br>Mindfulness</h2>
			<h3 fxFlex class="md-18 md-light-text-color">We believe in the huge power of taking time for you.</h3>
			<a fxFlex="50px" fxFlexAlign="center" fxLayout="row" href="https://apps.apple.com/us/app/beatfulness/id1120843229" class="no-decoration">
				<button fxFlexFill mat-raised-button color="accent" class="download-button md-light-background">
					<mat-icon iconPositionEnd>east</mat-icon>
					<span>Download</span>
				</button>
			</a>
		</dix>
		<dix fxFlex fxLayout="column" fxLayoutAlign="space-around center">
			<img fxFlex src="/assets/images/mockup.png">
		</dix>
	</div>
	<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutAlign="center stretch">
		<mat-card fxFlex="nogrow" fxLayout="column">
			<mat-card-content fxFlex fxLayout="column" fxLayoutAlign="center start" class="mat-elevation-z4">
				<img fxFlex class="card-icon" src="/assets/images/icon1.svg">
				<h2 fxFlex class="md-bold">Binaural Beats:</h2>
				<p fxFlex class="card-text md-18">25+ Categories of curated binaural beats for those who are new to binaural beats and those with expert ears.</p>
			</mat-card-content>
		</mat-card>
		<mat-card fxFlex="nogrow" fxLayout="column">
			<mat-card-content fxFlex fxLayout="column" fxLayoutAlign="center start" class="mat-elevation-z4">
				<img fxFlex class="card-icon" src="/assets/images/icon2.svg">
				<h2 fxFlex class="md-bold">Singles:</h2>
				<p fxFlex class="card-text md-18">Bite-sized binaural beats to practice tools for our daily life. Each session won’t take you more than 3 minutes.</p>
			</mat-card-content>
		</mat-card>
		<mat-card fxFlex="nogrow" fxLayout="column">
			<mat-card-content fxFlex fxLayout="column" fxLayoutAlign="center start" class="mat-elevation-z4">
				<img fxFlex class="card-icon" src="/assets/images/icon3.svg">
				<h2 fxFlex class="md-bold">Breathe:</h2>
				<p fxFlex class="card-text md-18">Take a moment for you today, to pause, to look around, to be grateful, to let go and Breathe…</p>
			</mat-card-content>
		</mat-card>
	</div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
