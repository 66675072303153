import {NgModule} from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import {IndexComponent} from './index.component';

const routes:Routes=[
	{path:'',component:IndexComponent,pathMatch:'full'}
];

@NgModule({
	imports:[RouterModule.forChild(routes)],
	exports:[RouterModule]
})
export class IndexRoutingModule{}
