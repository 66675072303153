import {Component} from '@angular/core';
import {AppService} from '../app.service';

@Component({
	selector:'beatfulness-index',
	templateUrl:'./index.component.html',
	styleUrls:['./index.component.scss']
})
export class IndexComponent{
	appService:AppService;
	
	constructor(appService:AppService){
		this.appService=appService;
	}
}