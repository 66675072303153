import {NgModule} from '@angular/core';
import {MatSliderModule} from '@angular/material/slider';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule,MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MAT_DATE_FORMATS,MAT_DATE_LOCALE,MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
import {MAT_DIALOG_DEFAULT_OPTIONS,MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS,MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
	declarations:[],
	imports:[
		MatSliderModule,
		LayoutModule,
		MatToolbarModule,
		MatButtonModule,
		MatSidenavModule,
		MatIconModule,
		MatListModule,
		MatGridListModule,
		MatCardModule,
		MatMenuModule,
		MatInputModule,
		MatSelectModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatTooltipModule,
		MatExpansionModule,
		MatChipsModule,
		MatBadgeModule,
		MatRippleModule,
		MatDialogModule,
		MatTabsModule,
		MatSnackBarModule,
		MatTableModule,
		MatRadioModule,
		MatSlideToggleModule
	],
	exports:[
		MatSliderModule,
		LayoutModule,
		MatToolbarModule,
		MatButtonModule,
		MatSidenavModule,
		MatIconModule,
		MatListModule,
		MatGridListModule,
		MatCardModule,
		MatMenuModule,
		MatInputModule,
		MatSelectModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatTooltipModule,
		MatExpansionModule,
		MatChipsModule,
		MatBadgeModule,
		MatRippleModule,
		MatDialogModule,
		MatTabsModule,
		MatSnackBarModule,
		MatTableModule,
		MatRadioModule,
		MatSlideToggleModule
	],
	providers:[
		{provide:MAT_MOMENT_DATE_ADAPTER_OPTIONS,useValue:{useUtc:false,strict:false}},
		{provide:MAT_DATE_LOCALE,useValue:'en-US'},
		{
			provide:MAT_DATE_FORMATS,
			useValue:{
				parse:{
					dateInput:['l','LL']
				},
				display:{
					dateInput:'L',
					monthYearLabel:'MMM YYYY',
					dateA11yLabel:'LL',
					monthYearA11yLabel:'MMMM YYYY'
				}
			}
		},
		{
			provide:MAT_DIALOG_DEFAULT_OPTIONS,
			useValue:{
				disableClose:true,
				hasBackdrop:true,
				autoFocus:'first-tabbable'
			}
		},
		{
			provide:MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue:{
				duration:3000,
				horizontalPosition:'center',
				verticalPosition:'top'
			}
		}
	]
})
export class CustomMaterialModule{}
