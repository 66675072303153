<h1 align="center">PAGE NOT FOUND</h1>
<div align="center">
	<a [routerLink]="['/']"><img src="/assets/icons/icon-1024x1024.png" alt="not found" title="not found" width="30%"></a>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>