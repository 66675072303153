import {Component,Inject,OnInit,Optional,PLATFORM_ID} from '@angular/core';
import {AppService} from '../../app.service';
import {Response} from 'express';
import {RESPONSE} from '@nestjs/ng-universal/dist/tokens';
import {isPlatformServer} from '@angular/common';

@Component({
	selector:'beatfuness-not-found',
	templateUrl:'./not-found.component.html',
	styleUrls:['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit{
	appService:AppService;
	title:string;
	private readonly platformId:any;
	response:Response;
	
	constructor(
		appService:AppService,
		@Optional() @Inject(RESPONSE) response:Response,
		@Inject(PLATFORM_ID) platformId:any
	){
		this.appService=appService;
		this.title='Beatfulness - Page not found';
		this.response=response;
		this.platformId=platformId;
		if(isPlatformServer(this.platformId)){
			this.response.setHeader('X-Robots-Tag','noindex');
			this.response.status(400);
		}
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'',
			''
		);
	}
	
}
