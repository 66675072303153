import {NgModule,isDevMode,PLATFORM_ID,APP_ID,Inject} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GraphQLModule} from './graphql.module';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {IndexModule} from './index/index.module';
import {getAnalytics,provideAnalytics,ScreenTrackingService,UserTrackingService} from '@angular/fire/analytics';
import {getApp,initializeApp,provideFirebaseApp} from '@angular/fire/app';
import {getAuth,provideAuth} from '@angular/fire/auth';
import {providePerformance,getPerformance} from '@angular/fire/performance';
import {environment} from '../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {isPlatformBrowser} from '@angular/common';
import {DrawerComponent} from './misc/drawer/drawer.component';
import {HeaderComponent} from './misc/header/header.component';
import {FooterModule} from './misc/footer/footer.module';

@NgModule({
	declarations:[
		AppComponent,
		DrawerComponent,
		HeaderComponent
	],
	imports:[
		BrowserModule.withServerTransition({appId:'serverApp'}),
		BrowserAnimationsModule,
		ServiceWorkerModule.register('ngsw-worker.js',{
			enabled:!isDevMode(),
			registrationStrategy:'registerWhenStable:30000'
		}),
		provideFirebaseApp(()=>initializeApp(environment.firebase,'beatfulness')),
		provideAnalytics(()=>getAnalytics(getApp('beatfulness'))),
		provideAuth(()=>getAuth(getApp('beatfulness'))),
		providePerformance(()=>getPerformance(getApp('beatfulness'))),
		SharedModule,
		IndexModule,
		AppRoutingModule,
		GraphQLModule,
		HttpClientModule,
		FooterModule
	],
	providers:[
		CookieService,
		// ScreenTrackingService,
		// UserTrackingService
	],
	bootstrap:[AppComponent]
})
export class AppModule{
	constructor(
		@Inject(PLATFORM_ID) private platformId:any,
		@Inject(APP_ID) private appId:string
	){
		console.debug(`Running ${isPlatformBrowser(platformId) ? 'in the browser' : 'on the server'} with appId=${appId}`);
	}
}