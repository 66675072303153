import {Component,Optional,PLATFORM_ID,Inject} from '@angular/core';
import {AppService} from '../../app.service';
import {ActivatedRoute} from '@angular/router';
import {Response} from 'express';
import {RESPONSE} from '@nestjs/ng-universal/dist/tokens';
import {isPlatformServer} from '@angular/common';

@Component({
	selector:'beatfulness-error',
	templateUrl:'./error.component.html',
	styleUrls:['./error.component.scss']
})
export class ErrorComponent{
	private activatedRouteService:ActivatedRoute;
	appService:AppService;
	title:string;
	message:string;
	private readonly platformId:any;
	response:Response;
	
	constructor(
		activatedRoute:ActivatedRoute,
		appService:AppService,
		@Optional() @Inject(RESPONSE) response:Response,
		@Inject(PLATFORM_ID) platformId:any
	){
		this.appService=appService;
		this.activatedRouteService=activatedRoute;
		this.title='';
		this.message='';
		this.response=response;
		this.platformId=platformId;
		if(isPlatformServer(this.platformId)){
			this.response.setHeader('X-Robots-Tag','noindex');
			this.response.status(400);
		}
		
	}
	
	ngOnInit():void{
		if(this.activatedRouteService.snapshot.fragment){
			this.title=this.activatedRouteService.snapshot.fragment.split('_')[0];
			this.message=this.activatedRouteService.snapshot.fragment.split('_')[1] || '400 BAD REQUEST';
		}else{
			this.title='ERROR';
			this.message='400 BAD REQUEST';
		}
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			'Beatfulness - '+this.title,
			'',
			''
		);
	}
}
