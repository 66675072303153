import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {AppService} from './app.service';

@Component({
	selector:'beatfulness-root',
	templateUrl:'./app.component.html',
	styleUrls:['./app.component.scss']
})
export class AppComponent{
	private domSanitizer:DomSanitizer;
	private matIconRegistry:MatIconRegistry;
	appService:AppService;
	title:string;
	
	constructor(domSanitizer:DomSanitizer,matIconRegistry:MatIconRegistry,appService:AppService){
		this.domSanitizer=domSanitizer;
		this.matIconRegistry=matIconRegistry;
		this.appService=appService;
		this.title='Beatfulness - Binaureal Beats with Sacred Geometry';
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Binaureal beats with sacred geometry for lucid dreaming, astral projections, healing, chakra activation, deep sleep and others.',
			'meditation,binaural,beats,relax,study,lucid,dreaming,concentration,learning,sacred,geometry,deep,sleep,creativity,focus',
			'https://www.beatfulness.com/assets/images/logo.png'
		);
		
		this.addIcons();
	}
	
	addIcons():void{
		this.matIconRegistry.addSvgIcon('app-twitter',this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-twitter.svg'));
		
	}
}
