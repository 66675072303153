<mat-toolbar class="drawer-header">
	<div fxLayout="row" style="width:100%">
		<button fxFlex="noshrink" mat-icon-button type="button" (click)="closeMenu()" aria-label="Close">
			<mat-icon class="md-24">close</mat-icon>
		</button>
		<div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center" fxFlexAlign="" dir="rtl">
			<button fxFlex="nogrow" mat-icon-button type="button" (click)="appService.toggleDarkTheme()" aria-label="Toggle dark mode" [matTooltip]="appService.darkTheme ? 'Light mode' : 'Dark mode'">
				<mat-icon>{{appService.darkTheme ? 'light_mode' : 'dark_mode'}}</mat-icon>
			</button>
		</div>
	</div>
</mat-toolbar>
<div fxLayout="column">
	<mat-nav-list fxFlex fxLayout="column" fxLayoutGap="10">
		<a mat-list-item [routerLink]="['/']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Home</span>
		</a>
		<a mat-list-item [routerLink]="['/privacy-policy']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Privacy policy</span>
		</a>
		<a mat-list-item [routerLink]="['/terms-of-use']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Terms of use</span>
		</a>
	</mat-nav-list>
	<div fxFlexFill>
		<div style="min-height:150px;">
			<div fxLayout="row" fxLayoutAlign="center center" style="position: absolute;bottom: 0px;right: 0px;width: 100%;" dir="rtl" class="drawer-footer">
				<div fxFlex fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="10px" fxLayoutGap="10px" style="margin: 15px 0px 15px 15px;">
					<a fxFlex="nogrow" href="https://twitter.com/beatfulness" fxFlexAlign="center" target="_blank" aria-label="Twitter">
						<mat-icon aria-label="twitter" [svgIcon]="'app-twitter'"></mat-icon>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
