import {Component} from '@angular/core';
import {AppService} from '../../app.service';

@Component({
	selector:'beatfulness-footer',
	templateUrl:'./footer.component.html',
	styleUrls:['./footer.component.scss']
})
export class FooterComponent{
	appService:AppService;
	
	constructor(appService:AppService){
		this.appService=appService;
	}
}
