<mat-error *ngIf="serverErrorInput" class="form-message-text">
	<ng-container *ngIf="serverErrorInput.graphQLErrors as errors;else normalError">
		<ng-container *ngIf="errors.slice()?.shift()?.extensions?.response as response;else messageError">
			Error: {{response.message?.toString()}}
		</ng-container>
		<ng-template #messageError>
			Error: {{errors.slice()?.shift().message}}
		</ng-template>
	</ng-container>
	<ng-template #normalError>
		{{serverErrorInput?.toString()}}
	</ng-template>
</mat-error>
<div *ngIf="serverResponseInput" class="form-message-text center">
	{{serverResponseInput?.toString()}}
</div>
