import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LightboxModule} from 'ng-gallery/lightbox';
import {GalleryModule} from 'ng-gallery';
import {CustomMaterialModule} from './custom-material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientJsonpModule,HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {FormMessageComponent} from './form-message/form-message.component';
import {InputEmptyToNullDirective} from './directives/input-empty-to-null.directive';


@NgModule({
	declarations:[
		FormMessageComponent,
		InputEmptyToNullDirective
	],
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		HttpClientJsonpModule,
		FlexLayoutModule.withConfig({addOrientationBps:true,useColumnBasisZero:false}),
		CustomMaterialModule,
		GalleryModule.withConfig({
			loop:true,
			thumb:false,
			autoPlay:false,
			playerInterval:10*1000,
			counter:false,
			dots:true
		}),
		LightboxModule.withConfig({
			keyboardShortcuts:true
		})
	],
	exports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		HttpClientJsonpModule,
		FlexLayoutModule,
		CustomMaterialModule,
		GalleryModule,
		LightboxModule,
		FormMessageComponent,
		InputEmptyToNullDirective
	]
})
export class SharedModule{}
