import {Component,OnInit} from '@angular/core';
import {AppService} from '../app.service';

@Component({
	selector:'beatfulness-terms-of-use',
	templateUrl:'./terms-of-use.component.html',
	styleUrls:['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit{
	appService:AppService;
	title:string;
	
	constructor(appService:AppService){
		this.appService=appService;
		this.title='Beatfulness - Terms of use';
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Binaureal beats with sacred geometry for lucid dreaming, astral projections, healing, chakra activation, deep sleep and others.',
			'meditation,binaural,beats,relax,study,lucid,dreaming,concentration,learning,sacred,geometry,deep,sleep,creativity,focus',
			'https://www.beatfulness.com/assets/images/logo.png'
		);
	}
	
}