/* eslint @typescript-eslint/explicit-function-return-type: ["off"] */
/* eslint @typescript-eslint/typedef: ["off"] */
import {NgModule} from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import {SelectivePreloadingStrategyService} from './common/selective-preloading-strategy.service';

const routes:Routes=[
	{path:'error',loadChildren:()=>import('./misc/error/error.module').then(m=>m.ErrorModule),pathMatch:'full'},
	{path:'privacy-policy',loadChildren:()=>import('./privacy-policy/privacy-policy.module').then(m=>m.PrivacyPolicyModule),data:{preload:true}},
	{path:'terms-of-use',loadChildren:()=>import('./terms-of-use/terms-of-use.module').then(m=>m.TermsOfUseModule),data:{preload:true}},
	{path:'**',loadChildren:()=>import('./misc/not-found/not-found.module').then(m=>m.NotFoundModule),data:{preload:true}}
];

@NgModule({
	imports:[RouterModule.forRoot(routes,{
		initialNavigation:'enabledNonBlocking',
		preloadingStrategy:SelectivePreloadingStrategyService
	})],
	exports:[RouterModule]
})
export class AppRoutingModule{}
