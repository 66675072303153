<header>
	<mat-toolbar>
		<mat-toolbar-row fxLayout="row" fxLayoutAlign="start center" class="main-row">
			<div *ngIf="(appService.isSmallObservable | async)" fxFlex="nogrow">
				<button mat-icon-button type="button" (click)="drawerInput?.toggle()" aria-label="Toggle sidenav">
					<mat-icon>menu</mat-icon>
				</button>
			</div>
			<div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
				<div *ngIf="!(appService.isSmallObservable | async)" fxFlex="nogrow" fxLayout="row">
					<a fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" [routerLink]="['/']" fxLayoutGap="20px" class="no-decoration">
						<img fxFlex="nogrow" [routerLink]="['/']" src="/assets/images/logo.svg" class="app-logo">
						<h1 fxFlex="nogrow" class="md-24">Beatfulness</h1>
					</a>
				</div>
				<div fxFlex="nogrow" fxFlex.lt-md="grow" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="space-between center" fxLayoutGap="10px">
					<h3 *ngIf="!(appService.isSmallObservable | async)" fxFlex="nogrow" fxFlexAlign="center">Available for</h3>
					<a fxFlex="nogrow" fxLayout="row" href="https://apps.apple.com/us/app/beatfulness/id1120843229">
						<img fxFlex="nogrow" src="/assets/images/appstore.svg" width="100px" height="auto">
					</a>
					<a *ngIf="false" fxFlex="nogrow" fxLayout="row" href="https://apps.apple.com/us/app/beatfulness/id1120843229">
						<img fxFlex="nogrow" src="/assets/images/playstore.svg" width="100px" height="auto">
					</a>
					<a *ngIf="(appService.isSmallObservable | async)" fxFlex="nogrow" fxLayout="row" href="https://twitter.com/beatfulness">
						<img fxFlex="nogrow" src="/assets/images/icon-twitter.svg" class="app-logo">
					</a>
				</div>
			</div>
		</mat-toolbar-row>
		<mat-toolbar-row *ngIf="(appService.isSmallObservable | async)">
			<div fxFlex="nogrow" fxLayout="row">
				<a fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" [routerLink]="['/']" fxLayoutGap="20px" class="no-decoration">
					<img fxFlex="nogrow" [routerLink]="['/']" src="/assets/images/logo.svg" class="app-logo">
					<h1 fxFlex="nogrow" class="md-24">Beatfulness</h1>
				</a>
			</div>
		</mat-toolbar-row>
	</mat-toolbar>
</header>
