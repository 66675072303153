<h1 align="center">{{title}}</h1>
<div align="center">
	<a [routerLink]="['/']"><img src="/assets/icons/icon-1024x1024.png" alt="bad request" title="bad request" width="30%"></a>
</div>
<h1 align="center">{{message}}</h1>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
