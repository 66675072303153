<mat-sidenav-container class="sidenav-container" [ngClass]="{'app-dark-theme':appService.darkTheme}">
	<mat-sidenav #drawer class="sidenav" fixedInViewport role="dialog" mode="over">
		<beatfulness-drawer [drawerInput]="drawer" (drawerClosed)="drawer.close()"></beatfulness-drawer>
	</mat-sidenav>
	<mat-sidenav-content>
		<beatfulness-header [drawerInput]="drawer"></beatfulness-header>
		<router-outlet></router-outlet>
		<beatfulness-footer></beatfulness-footer>
	</mat-sidenav-content>
</mat-sidenav-container>
